define("discourse/plugins/discourse-reactions/discourse/components/discourse-reactions-reaction-post", ["exports", "@ember/component", "@glimmer/component", "@ember/object/computed", "@ember/service", "discourse/lib/text", "discourse-common/lib/get-url", "@ember/template-factory"], function (_exports, _component, _component2, _computed, _service, _text, _getUrl, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UserStreamItem
    @item={{hash
      username=@reaction.post_user.username
      name=@reaction.post_user.name
      avatar_template=@reaction.post_user.avatar_template
      created_at=@reaction.created_at
      postUrl=this.postUrl
      category=@reaction.category
      title=@reaction.topic.title
      expandedExcerpt=@reaction.post.expandedExcerpt
      excerpt=@reaction.post.excerpt
      topic_id=@reaction.topic_id
      post_id=@reaction.post_id
      user_id=@reaction.user_id
    }}
  >
    <:bottom>
      {{#if @reaction.reaction.reaction_users_count}}
        <div class="discourse-reactions-my-reaction">
          <img src={{this.emojiUrl}} class="reaction-emoji" />
          <a
            href={{@reaction.user.userUrl}}
            data-user-card={{@reaction.user.username}}
            class="avatar-link"
          >
            {{avatar
              @reaction.user
              imageSize="tiny"
              extraClasses="actor"
              ignoreTitle="true"
            }}
          </a>
        </div>
      {{/if}}
    </:bottom>
  </UserStreamItem>
  */
  {
    "id": "nc1hgfvN",
    "block": "[[[8,[39,0],null,[[\"@item\"],[[28,[37,1],null,[[\"username\",\"name\",\"avatar_template\",\"created_at\",\"postUrl\",\"category\",\"title\",\"expandedExcerpt\",\"excerpt\",\"topic_id\",\"post_id\",\"user_id\"],[[30,1,[\"post_user\",\"username\"]],[30,1,[\"post_user\",\"name\"]],[30,1,[\"post_user\",\"avatar_template\"]],[30,1,[\"created_at\"]],[30,0,[\"postUrl\"]],[30,1,[\"category\"]],[30,1,[\"topic\",\"title\"]],[30,1,[\"post\",\"expandedExcerpt\"]],[30,1,[\"post\",\"excerpt\"]],[30,1,[\"topic_id\"]],[30,1,[\"post_id\"]],[30,1,[\"user_id\"]]]]]]],[[\"bottom\"],[[[[1,\"\\n\"],[41,[30,1,[\"reaction\",\"reaction_users_count\"]],[[[1,\"      \"],[10,0],[14,0,\"discourse-reactions-my-reaction\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,0,[\"emojiUrl\"]]],[14,0,\"reaction-emoji\"],[12],[13],[1,\"\\n        \"],[10,3],[15,6,[30,1,[\"user\",\"userUrl\"]]],[15,\"data-user-card\",[30,1,[\"user\",\"username\"]]],[14,0,\"avatar-link\"],[12],[1,\"\\n          \"],[1,[28,[35,3],[[30,1,[\"user\"]]],[[\"imageSize\",\"extraClasses\",\"ignoreTitle\"],[\"tiny\",\"actor\",\"true\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]]],[\"@reaction\"],false,[\"user-stream-item\",\"hash\",\"if\",\"avatar\"]]",
    "moduleName": "discourse/plugins/discourse-reactions/discourse/components/discourse-reactions-reaction-post.hbs",
    "isStrictMode": false
  });
  let DiscourseReactionsReactionPost = _exports.default = (_dec = (0, _computed.equal)("args.reaction.post.post_type", "site.post_types.moderator_action"), (_class = class DiscourseReactionsReactionPost extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "moderatorAction", _descriptor2, this);
    }
    get postUrl() {
      return (0, _getUrl.default)(this.args.reaction.post.url);
    }
    get emojiUrl() {
      const reactionValue = this.args.reaction.reaction.reaction_value;
      if (!reactionValue) {
        return;
      }
      return (0, _text.emojiUrlFor)(reactionValue);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "moderatorAction", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DiscourseReactionsReactionPost);
});